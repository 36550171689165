<template>
  <v-app-bar
    id="app-bar"
    absolute
    app
    color="transparent"
    flat
    height="75"
  >
    <v-btn
      class="mr-3"
      elevation="1"
      fab
      small
      @click="
        $vuetify.breakpoint.smAndDown
          ? setDrawer(!drawer)
          : $emit('input', !value)
      "
    >
      <v-icon v-if="value"> mdi-menu </v-icon>
      <v-icon v-else> mdi-menu </v-icon>
    </v-btn>

    <v-breadcrumbs
      class="pl-1"
      :items="breadcrumb"
    />

    <v-spacer />

    <div class="mx-3" />

    <v-menu
      bottom
      left
      min-width="200"
      offset-y
      origin="top right"
      transition="scale-transition"
    >
    </v-menu>
  </v-app-bar>
</template>

<script>
import { VHover, VListItem } from 'vuetify/lib';
import { mapState, mapMutations } from 'vuex';

export default {
  name: 'DashboardCoreAppBar',
  components: {
    // eslint-disable-next-line vue/no-unused-components
    AppBarItem: {
      render(h) {
        return h(VHover, {
          scopedSlots: {
            default: ({ hover }) => {
              return h(
                VListItem,
                {
                  attrs: this.$attrs,
                  class: {
                    'black--text': !hover,
                    'white--text primary elevation-12': hover
                  },
                  props: {
                    activeClass: '',
                    dark: hover,
                    link: true,
                    ...this.$attrs
                  }
                },
                this.$slots.default
              );
            }
          }
        });
      }
    }
  },
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    profile: [
      { title: 'Perfil', to: '/perfil/edit' },
      { divider: true },
      { title: 'Logout', to: '/perfil/logout' }
    ]
  }),
  computed: {
    ...mapState(['drawer']),
    breadcrumb() {
      let breadCrumbList = this.$route.meta.breadcrumb;
      return breadCrumbList.map((breadCrumb) => {
        if ('path' in breadCrumb) {
          return { text: breadCrumb.name, to: breadCrumb.path, exact: true };
        } else {
          return { text: breadCrumb.name, to: '/', exact: false };
        }
      });
    }
  },
  methods: {
    ...mapMutations({
      setDrawer: 'SET_DRAWER'
    })
  }
};
</script>
<style>
.v-breadcrumbs li {
  font-size: 1.25rem !important;
}
</style>
